import * as React from "react";
import {useEffect} from "react";
import {get as lodashGet} from "lodash";
import {Grid, MenuItem} from "@material-ui/core";
import {useStyles} from "./viewUserLogs.styles";
import {globalStyles} from "../../../../css/global.styles";
import {Container} from "../../../../components/container/container.component";
import {Form} from "react-final-form";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {Select} from "../../../../components/select/select";
import {IUser} from "../../../../interfaces/user";
import Cookies from "js-cookie";
import {Header} from "../../../../components/header/header.component";
import {Pagination} from "../../../../components/pagination";
import {getFormattedDateTime, getDayDifference} from "../../../../helpers/datetime";
import classnames from "classnames";

import Paper from '@material-ui/core/Paper';
import EditIcon from "@material-ui/icons/Create";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import {Locations as AdminLocations} from "../../constants/locations";
import {Redirect, useHistory} from "react-router-dom";
import {IFetchUserLogsParams} from "../../api/api";
import {ILog, ILogs} from "../../interfaces/logs";
import {Button} from "../../../../components/button/button";


export function ViewUserLogsComponent(props) {
	const {
		logs, fetchUserLogs, exchangedData,
	} = props;
	const classes = useStyles({});
	const [pageNr, setPageNr] = React.useState(1);
	const [selectedLog, setSelectedLog] = React.useState(null);
	let history = useHistory();
	const perPage = 30;
	const userData = lodashGet(exchangedData, "formValues", {});

	useEffect(() => {
		if (userData && userData.id) {
			fetchUserLogs({
				limit: perPage,
				page: pageNr,
				ownerId: userData.id,
			} as IFetchUserLogsParams);
		}
	}, [pageNr]);

	const handlePageClick = (selectedPageNr) => {
		setPageNr(selectedPageNr);
		setSelectedLog(null);
	};

	const handleOnRowClick = (log) => {
		setSelectedLog(log);
	};

	if (!Object.keys(userData).length) {
		return (
			<Redirect
				to={{
					pathname: AdminLocations.USERS,
				}}
			/>
		);
	} else {
		return (
			<>
				<Container>
					<Header>
						Logi dla: {userData.login}
					</Header>
					<div className={classes.tablesWrapper}>
						<table className={classes.table}>
							<tbody>
							<tr>
								<th className={classnames(classes.tableHeader, classes.colLP)}>L.p.</th>
								<th className={classnames(classes.tableHeader, classes.colLP)}>Akcja</th>
								<th className={classnames(classes.tableHeader, classes.colLP)}>Wynik</th>
								<th className={classnames(classes.tableHeader, classes.colLP)}>Data</th>
							</tr>
							{logs.items.map((log: ILog, index) => (
								<tr key={log.id} className={classnames(classes.tableRow, {[classes.tableRowSelected]: selectedLog && selectedLog.id === log.id})} onClick={() => {handleOnRowClick(log); }}>
									<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>{(pageNr - 1) * perPage + index + 1}</td>
									<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>{log.info}</td>
									<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>{log.type}</td>
									<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>{getFormattedDateTime(log.createdAt)}</td>
								</tr>
							))}
							{logs.items.length === 0 ? <tr><td colSpan={4}>Brak wyników</td></tr> : null}
							</tbody>
						</table>
						<table className={classes.logDetailsTable}>
							<tbody>
							<tr>
								<th className={classnames(classes.tableHeader, classes.colLP)} colSpan={2}>Szczegóły loga</th>
							</tr>
							<tr>
								<th className={classnames(classes.tableHeader, classes.colLP)}>Nazwa</th>
								<th className={classnames(classes.tableHeader, classes.colLP)}>Wartość</th>
							</tr>
							{selectedLog
								? (
									<>

										<tr><td>type</td><td>{selectedLog.type}</td></tr>
										<tr><td>info</td><td>{selectedLog.info}</td></tr>
										<tr><td>ownerId</td><td>{selectedLog.ownerId}</td></tr>
										<tr><td>cardId</td><td>{selectedLog.cardId}</td></tr>
										{selectedLog.additionalInfo
											? (
												<>
													<tr>
														<td>additionalInfo</td>
														<td>
															{JSON.stringify(selectedLog.additionalInfo)}
														</td>
													</tr>
												</>
											)
											: null}
										<tr><td>id</td><td>{selectedLog.id}</td></tr>
										<tr><td>createdAt</td><td>{selectedLog.createdAt}</td></tr>
									</>
									)
								: (
									<tr>
										<td colSpan={2}>Nie wybrano loga</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</Container>
				<Pagination page={pageNr} pageCount={logs.pageCount} onPageClick={handlePageClick} />
			</>
		);
	}
}
