import {connect} from "react-redux";
import {PasswordComponent} from "./password.component";
import {getError, getRefreshCurrentPageStatus, getUserId} from "../../../../store/selectors/app.selectors";
import {ISettingsPasswordFormValues} from "../../../../interfaces/forms/settingsPassword";
import {changePassword, clearRefreshCurrentPageAction} from "../../../../store/actions";

const mapStateToProps = (state) => {
	return {
		error: getError(state),
		userId: getUserId(state),
		refreshCurrentPageStatus: getRefreshCurrentPageStatus(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changePassword: (userId, formValues: ISettingsPasswordFormValues) => {dispatch(changePassword(userId, formValues))},
		clearRefreshCurrentPage: () => {dispatch(clearRefreshCurrentPageAction())}
	};
};

export const PasswordSection = connect(
	mapStateToProps,
	mapDispatchToProps
)(PasswordComponent);
