export function isValidEmail(email) {
	if (!email) {
		return false;
	}

	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return re.test(String(email).toLowerCase());
}

export function isValidPassword(password) {
	const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{3,}$/;

	if (!password) {
		return false;
	}

	if (password.length < 8 || password.length > 20) {
		return false;
	}

	if (!re.test(String(password))) {
		return false;
	}

	return true;
}

export function isAlphanumeric(login) {
	if (!login) {
		return false;
	}

	const re = /^[a-zA-Z0-9]+$/;

	return re.test(String(login));
}

export function isValidLogin(login) {
	return login && login.length >= 5 && login.length <= 50;
}

export function isValidDateTime(datetime) {
	if (!datetime) {
		return false;
	}

	// 2020-03-01T00:00:00.000Z
	const re = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}\T[0-9]{2}\:[0-9]{2}\:[0-9]{2}\.[0-9]{3}\Z$/;

	return re.test(datetime);
}

export function containsSpaces(str) {
	if (!str) {
		return false;
	}

	// \s means "any whitespace character" (spaces, tabs, vertical tabs, formfeeds, line breaks, etc.), and will find that character anywhere in the string.
	if (/\s/.test(str)) {
		return true;
	}

	return false;
}
