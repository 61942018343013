import {connect} from "react-redux";
import {LoginComponent} from "./login.component";
import {ILoginFormValues} from "../../interfaces/forms/login";
import {isLoggedIn, getAccessToken} from "../../store/selectors/app.selectors";
import {getError} from "../../store/selectors/app.selectors";
import {login, updateFormDataAction, getUser} from "../../store/actions";

const mapStateToProps = (state) => {
	return {
		isLoggedIn: isLoggedIn(state),
		error: getError(state),
		accessToken: getAccessToken(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		login: (roleType, formValues: ILoginFormValues) => {dispatch(login(roleType, formValues))},
		getUser: (userId, locationToChangeTo) => {dispatch(getUser(userId, locationToChangeTo))},
		updateFormData: (exchangedData) => {dispatch(updateFormDataAction(exchangedData))},
	};
};

export const LoginRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginComponent);
