import {Field} from "react-final-form";
import * as React from "react";
import {
	FormControl,
	Select as SelectMaterialUi,
	InputLabel, TextField,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120,
		width: "100%",
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	selectRoot: {
		minHeight: "19px",
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
}));

const ReactMultiSelectAdapter = ({ input, meta, required, serverErrors, ...rest }) => {
	const classes = useStyles({});
	const labelName = `outlined-age-native-simple-${input.name}`;
	const inputLabel = React.useRef(null);
	const [labelWidth, setLabelWidth] = React.useState(0);
	const [multiValue, setMultiValue] = React.useState([]);
	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	const componentName = input.name;
	const found = (serverErrors || []).find((error) => {
		return error.name === componentName;
	});

	const hasError = (meta.error && meta.touched) || !!found;
	const errorMessage = (meta.error && meta.touched && meta.error) || (found && found.value);

	React.useEffect(() => {
		if (inputLabel) {
			setLabelWidth(inputLabel!.current.offsetWidth);
		}
	}, []);

	return (
		<FormControl variant="outlined" size="small" className={classes.formControl} required={required || false} error={hasError}>
			<InputLabel id={`${labelName}-label`} ref={inputLabel}>
				{rest.label}
			</InputLabel>
			<SelectMaterialUi
				labelId={`${labelName}-label`}
				classes={{root: classes.selectRoot}}
				id={labelName}
				labelWidth={labelWidth}
				multiple={true}
				value={multiValue}
				displayEmpty
				inputProps={{
					id: labelName,
				}}
				{...input}
				children={rest.children}
				onChange={(event, el) => {
					if (typeof rest.onChange === "function") {
						rest.onChange(event.target.value);
					}

					setMultiValue(event.target.value as any);

					return input.onChange(event.target.value);
				}}
				renderValue={(selected: any) => (
					<div className={classes.chips}>
						{selected.map(value => (
							<Chip key={value} label={value} className={classes.chip} size="small" />
						))}
					</div>
				)}
				MenuProps={MenuProps}
			>
			</SelectMaterialUi>
			{hasError && <FormHelperText>{errorMessage}</FormHelperText>}
		</FormControl>
	);
};

export function MultiSelect(props) {
	const {children, ...rest} = props;

	return (
		<Field
			component={ReactMultiSelectAdapter}
			{...rest}
		>
			{children}
		</Field>
	);
}
