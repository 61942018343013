import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {

	},

	container: {
		padding: "60px 30px 80px",
		margin: "0 auto",
	}
}));

