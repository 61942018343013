import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	tableValue: {
		wordBreak: "break-all"
	}
}));

