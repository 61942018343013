import {connect} from "react-redux";
import {RegisterComponent} from "./register.component";
import {getError} from "../../store/selectors/app.selectors";
import {register} from "../../store/actions";
import {IRegisterFormValues} from "../../interfaces/forms/register";

const mapStateToProps = (state) => {
	return {
		error: getError(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		register: (accountType, formValues: IRegisterFormValues, locationToChangeTo, recaptchaToken) => {dispatch(register(accountType, formValues, locationToChangeTo, recaptchaToken))},
	};
};

export const RegisterRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(RegisterComponent);
