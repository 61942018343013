import * as React from "react";
import {useStyles} from "./container.styles";
import classnames from "classnames";

export function Container(props) {
	const {children} = props;
	const classes = useStyles({});

	return (
		<div className={classes.root}>{children}</div>
	);
}

