import {connect} from "react-redux";
import {TopMenuComponent} from "./topMenu.component";
import {logoutAction} from "../../../../store/actions";
import {isLoggedIn, getLoginName} from "../../../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		isLoggedIn: isLoggedIn(state),
		loginName: getLoginName(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => {dispatch(logoutAction())}
	};
};

export const TopMenu = connect(
	mapStateToProps,
	mapDispatchToProps
)(TopMenuComponent);


