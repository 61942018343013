import {connect} from "react-redux";
import {BottomMenuComponent} from "./bottomMenu.component";
import {withRouter} from "react-router";

const mapStateToProps = (state) => {
	return {
		// todos: getVisibleTodos(state.todos, state.visibilityFilter)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		// login: (formValues: ILoginFormValues) => {dispatch(loginAction(formValues))}
	};
};

export const BottomMenu = connect(
	mapStateToProps,
	mapDispatchToProps
)(BottomMenuComponent);


