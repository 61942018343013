import {connect} from "react-redux";
import {ViewUserLogsComponent} from "./viewUserLogs.component";
import {fetchUserLogs} from "../../store/actions";
import {getLogs} from "../../store/selectors/app.selectors";
import {updateFormDataAction} from "../../../../store/actions/updateFormData.actions";
import {getApiType, getFormData} from "../../../../store/selectors/app.selectors";
import {IFetchUserLogsParams} from "../../api/api";

const mapStateToProps = (state) => {
	return {
		logs: getLogs(state),
		exchangedData: getFormData(state),
		apiType: getApiType(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchUserLogs: (queryData: IFetchUserLogsParams) => {dispatch(fetchUserLogs(queryData))},
		updateFormData: (exchangedData) => {dispatch(updateFormDataAction(exchangedData))},
	};
};

export const ViewUserLogsRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(ViewUserLogsComponent);
