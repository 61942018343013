import axios from "axios";
import Cookies from "js-cookie";
import {flatternObjectToGetParams, getServerUrl} from "../../../helpers/server";
import {IEditUserFormValues} from "../interfaces/forms/editUser";

export function fetchUsers(apiType) {
	return axios({
		method: 'get',
		url: `${getServerUrl()}/users`,
		responseType: 'json',
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export interface IFetchUserLogsParams {
	limit: number;
	page: number;
	type: "success" | "error";
	ownerId: string;
	dateFrom: string;
	dateTo: string;
}

export function fetchUserLogs(queryData: IFetchUserLogsParams) {
	return axios({
		method: 'get',
		url: `${getServerUrl()}/log${flatternObjectToGetParams(queryData)}`,
		responseType: 'json',
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function editUser(userId, formValues: IEditUserFormValues) {
	return axios({
		method: 'patch',
		url: `${getServerUrl()}/users/${userId}`,
		data: {
			login: formValues.login,
			email: formValues.email,
			regulationsConfirmed: formValues.regulationsConfirmed,
			roles: formValues.roles,
			validUntil: formValues.validUntil,
			status: formValues.status,
		},
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function deleteUser(userId) {
	return axios({
		method: 'delete',
		url: `${getServerUrl()}/users/${userId}`,
		data: {
			userId: userId,
		},
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function editTokens(userId, clientId, clientKey) {
	return axios({
		method: 'patch',
		url: `${getServerUrl()}/users/${userId}/bdocredentials`,
		data: {
			clientId: clientId,
			clientKey: clientKey,
		},
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function setPassword(userId, password, confirmedPassword) {
	return axios({
		method: 'patch',
		url: `${getServerUrl()}/users/${userId}/setPassword`,
		data: {
			password: password,
			confirmedPassword: confirmedPassword,
		},
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}
