import axios from "axios";
import Cookies from "js-cookie";
import {ISettingsTokensFormValues} from "../interfaces/forms/settingsTokens";
import {ISettingsPasswordFormValues} from "../interfaces/forms/settingsPassword";
import {getServerUrl} from "../helpers/server";
import {ILoginFormValues} from "../interfaces/forms/login";
import {RoleTypes} from "../constants/roleTypes";
import {IRegisterFormValues} from "../interfaces/forms/register";

export function updateBDOCredentials(userId, formValues: ISettingsTokensFormValues) {
	return axios({
		method: 'patch',
		url: `${getServerUrl()}/users/${userId}/bdocredentials`,
		data: {
			clientId: formValues.clientId,
			clientKey: formValues.clientKey,
		},
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function changePassword(userId, formValues: ISettingsPasswordFormValues) {
	return axios({
		method: 'patch',
		url: `${getServerUrl()}/users/${userId}/changepassword`,
		data: {
			password: formValues.password,
			confirmedPassword: formValues.confirmedPassword,
			oldPassword: formValues.oldPassword,
		},
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function getUser(userId) {
	return axios({
		method: 'get',
		url: `${getServerUrl()}/users/${userId}`,
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + Cookies.get("mbdo_at"),
		},
	});
}

export function login(roleType, formValues: ILoginFormValues) {
	return axios({
		method: 'post',
		url: `${getServerUrl()}/login`,
		data: {
			login: formValues.login,
			password: formValues.password,
			eupId: formValues.eupId,
			role: roleType,
		},
		headers: {
			"Content-Type": "application/json"
		},
	});
}

export function register(accountType, formValues: IRegisterFormValues, recaptchaToken: string) {
	return axios({
		method: 'post',
		url: `${getServerUrl()}/signup/${accountType}`,
		data: {
			login: formValues.login,
			email: formValues.email,
			password: formValues.password,
			confirmedPassword: formValues.confirmedPassword,
			regulationsConfirmation: formValues.regulationsConfirmation,
			"g-recaptcha-response": recaptchaToken,
		},
		headers: {
			"Content-Type": "application/json"
		},
	});
}
