import {connect} from "react-redux";
import {SelectPlaceOfBusinessComponent} from "./selectPlaceOfBusiness.component";
import {ILoginFormValues} from "../../interfaces/forms/login";
import {getAccessToken, getFormData, getPlacesOfBusiness} from "../../store/selectors/app.selectors";
import {getError} from "../../store/selectors/app.selectors";
import {getUser, login} from "../../store/actions";

const mapStateToProps = (state) => {
	return {
		error: getError(state),
		exchangedData: getFormData(state),
		placesOfBusiness: getPlacesOfBusiness(state),
		accessToken: getAccessToken(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		login: (roleType, formValues: ILoginFormValues) => {dispatch(login(roleType, formValues))},
		getUser: (userId, locationToChangeTo) => {dispatch(getUser(userId, locationToChangeTo))},
	};
};

export const SelectPlaceOfBusinessRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(SelectPlaceOfBusinessComponent);
