import {IRegisterFormValues} from "../../interfaces/forms/register";
import {register as registerAPI} from "../../api/api";
import {TYPE_REGISTER, TYPE_REGISTER_FAILURE, TYPE_REGISTER_SUCCESS} from "./types";

export function register(accountType, formValues: IRegisterFormValues, locationToChangeTo, recaptchaToken) {
	return function(dispatch) {
		dispatch(registerAction());

		return registerAPI(accountType, formValues, recaptchaToken).then((response) => {
			dispatch(registerSuccessAction(response, locationToChangeTo, formValues));
		}).catch((error) => {
			dispatch(registerFailureAction(error));
		});
	};
}

export function registerAction() {
	return {
		type: TYPE_REGISTER,
		isLoading: true,
	};
}

export function registerSuccessAction(response, locationToChangeTo, formValues) {
	return {
		type: TYPE_REGISTER_SUCCESS,
		isLoading: false,
		response: response,
		locationToChangeTo,
		formValues,
	};
}

export function registerFailureAction(error) {
	return {
		type: TYPE_REGISTER_FAILURE,
		isLoading: false,
		error,
	};
}
