import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	rootTop: {
		alignItems: "flex-start",
	},
	rootCenter: {
		alignItems: "center",
	},
	rootBottom: {
		alignItems: "flex-end",
	},
	label: {
		fontSize: "14px"
	}
}));






