import React from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useStyles} from "./updateTokens.styles";
import {globalStyles} from "../../css/global.styles";
import {Header} from "../../components/header/header.component";
import {Message} from "../../components/message";
import {Container} from "../../components/container/container.component";
import {Button} from "../../components/button/button";
import {FormFieldWrapper} from "../../components/formFieldWrapper/formFieldWrapper.component";
import {Locations} from "../../constants/locations";

export function UpdateTokensComponent(props) {
	const {error, updateFormData} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	let history = useHistory();
	let location = useLocation() as any;

	let { from } = location.state || { from: { pathname: "/" } };

	return (
		<Container>
			<Header>
				Brakujące tokeny BDO
			</Header>
			<Message>
				Aby korzystać z aplikacji MobileBDO musisz podłączyć swoje firmowe konto BDO poprzez ustawienie tokenów autoryzacyjnych "Client ID" oraz "Client Secret".
				Jeśli nie wiesz jak to zrobić, skorzystaj z działu pomocy na stronie <a href="https://www.mbdo.pl/pomoc/tokeny-bdo.html" target="_blank">https://www.mbdo.pl/pomoc/tokeny-bdo.html</a>. <br /><br />
				<FormFieldWrapper alignContent="center">
					<Link to={Locations.SETTINGS}>
						<Button variant="contained" color="primary">Przejdź do ustawień</Button>
					</Link>
				</FormFieldWrapper>
			</Message>
		</Container>
	);
}



