import * as React from "react";
import {useEffect} from "react";
import {Grid, MenuItem} from "@material-ui/core";
import {useStyles} from "./users.styles";
import {globalStyles} from "../../../../css/global.styles";
import {Container} from "../../../../components/container/container.component";
import {Form} from "react-final-form";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {Select} from "../../../../components/select/select";
import {IUser} from "../../../../interfaces/user";
import Cookies from "js-cookie";
import {Header} from "../../../../components/header/header.component";
import {Button} from "../../../../components/button/button";
import {getFormattedDate, getDayDifference} from "../../../../helpers/datetime";
import classnames from "classnames";
import Paper from '@material-ui/core/Paper';
import EditIcon from "@material-ui/icons/Create";
import RemoveIcon from "@material-ui/icons/Delete";
import LogsIcon from "@material-ui/icons/Assessment";
import AddIcon from "@material-ui/icons/Add";
import {Locations as AdminLocations} from "../../constants/locations";
import {useHistory} from "react-router-dom";
import {DeleteUserDialog} from "../../containers/dialogs/deleteUser/deleteUserDialog";

export function UsersComponent(props) {
	const {
		users, fetchUsers, updateFormData, apiType, deleteUser, refreshCurrentPageStatus, clearRefreshCurrentPage
	} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});
	const [state, setState] = React.useState({
		opened: false,
		user: null,
	});

	let history = useHistory();

	useEffect(() => {
		fetchUsers(apiType);
	}, []);

	useEffect(() => {
		if (refreshCurrentPageStatus !== null) {
			clearRefreshCurrentPage();
			fetchUsers(apiType);
		}
	}, [refreshCurrentPageStatus]);

	const handleEditClick = (userId) => {
		updateFormData({
			from: AdminLocations.USERS,
			userId: userId,
			formValues: users.find((user: IUser) => (user.id === userId)),
		});
		history.push(AdminLocations.EDIT_USER);
	};

	const handleViewLogsClick = (userId) => {
		updateFormData({
			from: AdminLocations.USERS,
			userId: userId,
			formValues: users.find((user: IUser) => (user.id === userId)),
		});
		history.push(AdminLocations.VIEW_USER_LOGS);
	};

	const handleDeleteClick = (user) => {
		setState((prevState) => {
			return {
				opened: true,
				user,
			};
		});
	};

	function handleDeleteUserModalConfirm() {
		if (state.user) {
			deleteUser(state.user.id);
		}

		setState((prevState) => {
			return {
				opened: false,
				user: null,
			};
		});
	}

	function handleDeleteUserModalCancel() {
		setState((prevState) => {
			return {
				opened: false,
				user: null,
			};
		});
	}

	function handleDeleteUserModalClose() {
		setState((prevState) => {
			return {
				opened: false,
				user: null,
			};
		});
	}

	return (
		<>
			<DeleteUserDialog open={state.opened} userToDelete={state.user} onConfirm={handleDeleteUserModalConfirm} onCancel={handleDeleteUserModalCancel} onClose={handleDeleteUserModalClose} />
			<Container>
				<Header>
					Użytkownicy
				</Header>
				<Paper>
					<table className={classes.table}>
						<tbody>
						<tr>

							<th className={classnames(classes.tableHeader)}>L.p.</th>
							<th className={classnames(classes.tableHeader)}>Login</th>
							<th className={classnames(classes.tableHeader)}>Email</th>
							<th className={classnames(classes.tableHeader)}>Role</th>
							<th className={classnames(classes.tableHeader)}>Status</th>
							<th className={classnames(classes.tableHeader)}>Aktywny do</th>
							<th className={classnames(classes.tableHeader)}>Ost. zmiana</th>
							<th className={classnames(classes.tableHeader)}>Tokeny BDO?</th>
							<th className={classnames(classes.tableHeader)}>Akcje</th>

						</tr>
						{users.map((user: IUser, index) => (
							<tr key={user.id}>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{index + 1}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})} title={user.id}>
									{user.login}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{user.email || ""}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{(user.roles || []).join(", ")}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{user.status}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{user.validUntil ? <span className={classnames({[classes.expired]: user.isExpired})}>{getFormattedDate(user.validUntil)}</span> : ""}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{user.updatedAt ? <span>{getDayDifference(new Date(user.updatedAt), new Date())} dni temu</span> : ""}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{user.hasBdoCredentials ? "tak" : <span className={classes.expired}>nie</span>}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									<Button onClick={() => {handleEditClick(user.id)}} variant="contained" color="primary" startIcon={<EditIcon />} style={{marginRight: "10px"}}>Edytuj</Button>
									<Button onClick={() => {handleViewLogsClick(user.id)}} variant="contained" color="primary" startIcon={<LogsIcon />} style={{marginRight: "10px"}}>Logi</Button>
									<Button onClick={() => {handleDeleteClick(user)}} variant="contained" color="secondary" startIcon={<RemoveIcon />}>Usuń</Button>
								</td>
							</tr>
						))}
						</tbody>
					</table>
				</Paper>
				<Button variant="contained" color="primary" disabled={true} startIcon={<AddIcon />} style={{marginTop: "10px"}}>Dodaj użytkownika</Button>
			</Container>
		</>
	);
}
