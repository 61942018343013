import React from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useStyles} from "./settings.styles";
import {globalStyles} from "../../css/global.styles";
import {Header} from "../../components/header/header.component";
import {Container} from "../../components/container/container.component";
import {TokensSection} from "./parts/tokens";
import {PasswordSection} from "./parts/password";
import {InvoiceSection} from "./parts/invoice";
import {Accordion} from "../../components/accordion";

export function SettingsComponent(props) {
	const {error, updateFormData} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	let history = useHistory();
	let location = useLocation() as any;

	let { from } = location.state || { from: { pathname: "/" } };

	return (
		<Container>
			<Header>
				Ustawienia
			</Header>
			<Accordion title="Tokeny autoryzacyjne BDO">
				<TokensSection />
			</Accordion>
			<Accordion title="Zmiana hasła">
				<PasswordSection />
			</Accordion>
			{false && <Accordion title="Dane do faktury">
				<InvoiceSection />
			</Accordion>}
		</Container>
	);
}



