import * as React from "react";
import {useStyles} from "./dialog.styles";
import {Button} from "../button/button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export function DialogComponent(props) {
	const {open = false, onCancel, onConfirm, onClose, title, description, confirmButtonLabel, cancelButtonLabel} = props;
	const classes = useStyles({});

	const handleConfirm = () => {
		if (onConfirm) {
			onConfirm();
		}
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{description}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel} color="secondary" variant="contained">
						{cancelButtonLabel}
					</Button>
					<Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
						{confirmButtonLabel}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

