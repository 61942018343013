import {Field} from "react-final-form";
import * as React from "react";
import {
	FormControl,
	Select as SelectMaterialUi,
	InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120,
		width: "100%",
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

const ReactSelectAdapter = ({ input, required, ...rest }) => {
	const classes = useStyles({});
	const labelName = `outlined-age-native-simple-${input.name}`;
	const inputLabel = React.useRef(null);
	const [labelWidth, setLabelWidth] = React.useState(0);

	React.useEffect(() => {
		if (inputLabel) {
			setLabelWidth(inputLabel!.current.offsetWidth);
		}
	}, []);

	return (
		<FormControl variant="outlined" size="small" className={classes.formControl} required={required || false}>
			<InputLabel id={`${labelName}-label`} ref={inputLabel}>
				{rest.label}
			</InputLabel>
			<SelectMaterialUi
				labelId={`${labelName}-label`}
				id={labelName}
				labelWidth={labelWidth}
				value={input.value}
				displayEmpty
				inputProps={{
					id: labelName,
				}}
				{...input}
				children={rest.children}
				onChange={(event, el) => {
					if (typeof rest.onChange === "function") {
						rest.onChange(event.target.value);
					}

					return input.onChange(event.target.value);
				}}
			>
			</SelectMaterialUi>
		</FormControl>
	);
};

export function Select(props) {
	const {children, ...rest} = props;

	return (
		<Field
			component={ReactSelectAdapter}
			{...rest}
		>
			{children}
		</Field>
	);
}
