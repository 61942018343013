import React from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useStyles} from "./accountExpired.styles";
import {globalStyles} from "../../css/global.styles";
import {Header} from "../../components/header/header.component";
import {Message} from "../../components/message";
import {Container} from "../../components/container/container.component";
import {Button} from "../../components/button/button";
import {FormFieldWrapper} from "../../components/formFieldWrapper/formFieldWrapper.component";
import {Locations} from "../../constants/locations";
import {getFormattedDateTime} from "../../helpers/datetime";

export function AccountExpiredComponent(props) {
	const {error, updateFormData, expirationDate} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	let history = useHistory();
	let location = useLocation() as any;

	let { from } = location.state || { from: { pathname: "/" } };

	if (!expirationDate) {
		return <div></div>;
	}

	return (
		<Container>
			<Header>
				Twoje konto jest nieaktywne
			</Header>
			<Message>
				Twoje konto straciło ważność <b>{getFormattedDateTime(expirationDate)}</b>. Prosimy i opłacenie abonamentu na kolejny miesiąc.
				<br /><br />
				W razie pytań lub wątpliwości prosimy o kontakt.
			</Message>
		</Container>
	);
}



