import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		color: "black",
		fontSize: "15px",
		fontFamily: theme.typography.fontFamily,
		padding: "10px"
	},
	page: {
		display: "inline-block",
		borderRadius: "3px",
		padding: "10px",
		border: "1px solid transparent",
		cursor: "pointer",

		"&:hover": {
			border: "1px solid rgba(26, 108, 64, 1)",
		}
	},
	selected: {
		backgroundColor: "rgba(26, 108, 64, 0.4)",
		border: "1px solid rgba(26, 108, 64, 1)",
		cursor: "default",
	}
}));
