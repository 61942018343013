import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	space: {
		marginRight: theme.spacing(2),
	},
	loginButton: {
		marginRight: theme.spacing(2),
	},
	form: {
		width: "100%"
	}
}));
