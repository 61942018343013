import React from "react";
import {useStyles} from "./message.styles";
import {Typography} from '@material-ui/core';
import classnames from "classnames";

export function MessageComponent(props) {
	const {children, type} = props;
	const classes = useStyles({});

	return (
		<div className={classnames(classes.root, {[classes.error]: type === "error"})}>
			{children}
		</div>
	);
}
