import React from "react";
import classnames from "classnames";
import {Form, Field} from "react-final-form";
import {Textbox} from "../../../../components/textbox/textbox";
import {Button} from "../../../../components/button/button";
import {ErrorMessage} from "../../../../components/errorMessage";
import {MenuItem, Grid, Paper, Typography} from '@material-ui/core';
import {Link, useHistory, useLocation} from "react-router-dom";
import {ISettingsInvoiceFormValues} from "../../../../interfaces/forms/settingsInvoice";
import {useStyles} from "./invoice.styles";
import {globalStyles} from "../../../../css/global.styles";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {Locations} from "../../../../constants/locations";

export function InvoiceComponent(props) {
	const {error, updateFormData} = props;
	const classes = useStyles({});
	const globalClasses = globalStyles({});

	let history = useHistory();
	let location = useLocation() as any;

	let { from } = location.state || { from: { pathname: "/" } };

	const initialValues: ISettingsInvoiceFormValues = {
		companyName: "",
		nip: "",
		street: "",
		houseNumber: "",
		flatNumber: "",
		zipCode: "",
		city: "",
	};

	const onSubmit = async (values: ISettingsInvoiceFormValues) => {
		// updateFormData({
		// 	from: Locations.LOGIN,
		// 	formValues: values,
		// });
		//
		// login(values, from.pathname);
	};

	const handleLoginButtonClick = () => {
		history.push(Locations.LOGIN);
	};

	const handleTokensValidation = (values: ISettingsInvoiceFormValues) => {
		const errors: any = {};

		if (!values.companyName) {
			errors.companyName = "To pole jest wymagane";
		}

		if (!values.nip) {
			errors.nip = "To pole jest wymagane";
		}

		if (!values.street) {
			errors.street = "To pole jest wymagane";
		}

		if (!values.houseNumber) {
			errors.houseNumber = "To pole jest wymagane";
		}

		if (!values.flatNumber) {
			errors.flatNumber = "To pole jest wymagane";
		}

		if (!values.zipCode) {
			errors.zipCode = "To pole jest wymagane";
		}

		if (!values.city) {
			errors.city = "To pole jest wymagane";
		}

		return errors;
	};

	return (
		<>
			<Form
				validate={handleTokensValidation}
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, form, submitting, pristine, values }) => {
					return (
						<form onSubmit={handleSubmit} className={classes.form}>
							<FormFieldWrapper>
								<Textbox
									name="companyName"
									label="Nazwa firmy"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textbox
									name="nip"
									label="NIP"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textbox
									name="street"
									label="Ulica"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textbox
									name="houseNumber"
									label="Numer domu"
									className={classnames(globalClasses.inputWidth, classes.space)}
									serverErrors={error}

								/>
								<Textbox
									name="flatNumber"
									label="Numer mieszkania"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper>
								<Textbox
									name="zipCode"
									label="Kod pocztowy"
									className={classnames(globalClasses.inputWidth, classes.space)}
									serverErrors={error}

								/>
								<Textbox
									name="city"
									label="Miasto"
									className={globalClasses.inputWidth}
									serverErrors={error}
								/>
							</FormFieldWrapper>
							<FormFieldWrapper alignContent="right">
								<Button variant="contained" color="primary" type="submit" disabled={Boolean(Object.keys(handleTokensValidation(values)).length)}>Zapisz zmiany</Button>
							</FormFieldWrapper>

							<ErrorMessage errors={error} type="general" />
						</form>
					)
				}}
			/>
		</>
	);
}



