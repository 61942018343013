import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		display: "flex",
		fontSize: "16px",
		fontFamily: theme.typography.fontFamily,
		padding: "10px 0",
		justifyContent: "start",
		alignItems: "center",
	},
}));






