import React from "react";
import {useStyles} from "./pagination.styles";
import classnames from "classnames";

export function PaginationComponent(props) {
	const {children, page = 1, pageCount = 1, onPageClick = () => {}} = props;
	const classes = useStyles({});

	function handlePageClick(pageNr) {
		onPageClick(pageNr);
	}

	function getPages() {
		const el = [];

		for (let i = 1; i <= pageCount; i++) {
			el.push(
				<div className={classnames(classes.page, {[classes.selected]: i === page})} onClick={() => {handlePageClick(i)}} key={i}>{i}</div>
			);
		}

		return el;
	}

	return (
		<div className={classes.root}>
			{getPages()}
		</div>
	);
}
