import {Field} from "react-final-form";
import * as React from "react";
import {TextField} from '@material-ui/core/';

const TextFieldAdapter = ({input, meta, serverErrors, ...rest}) => {
	const componentName = input.name;
	const found = (serverErrors || []).find((error) => {
		return error.name === componentName;
	});

	const hasError = (meta.error && meta.touched) || !!found;
	const errorMessage = (meta.error && meta.touched && meta.error) || (found && found.value);

	return (
		<TextField
			id={"mbdo_texbox_" + componentName}
			variant="outlined"
			size="small"
			helperText={errorMessage}
			error={hasError}
			InputLabelProps={{
				shrink: true,
			}}
			{...input}
			{...rest}
			onChange={(event, value) => {
				return input.onChange(event.target.value);
			}}
		/>
	);
};

export function Textbox(props) {
	return (
		<Field
			component={TextFieldAdapter}
			{...props}
		/>
	);
}
