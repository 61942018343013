import {Button as ButtonMaterialUi} from "@material-ui/core";
import * as React from "react";
import {useStyles} from "./buttonText.styles";

export function ButtonText(props) {
	const {children, ...rest} = props;

	const classes = useStyles({});

	return (
		<ButtonMaterialUi {...rest}>{children}</ButtonMaterialUi>
	);
}

