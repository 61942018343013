import React from "react";
import {useStyles} from "./errorMessage.styles";
import {Typography} from '@material-ui/core';
import classnames from "classnames";
import {containsError, getError} from "../../helpers/errors";

export function ErrorMessageComponent(props) {
	const {children, errors, type} = props;
	const classes = useStyles({});
	let message: string = "";

	if (!errors) {
		return null;
	}

	if (containsError(type, errors)) {
		message = getError(type, errors).value;
	}

	return (
		<div className={classnames(classes.root)}>
			{message ||  errors.map((error, index) => {
				return (<div key={index}>{error.message}</div>);
			})}
		</div>
	);
}
