import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {RouterHelperComponent} from "./routerHelper.component";
import {getLocationToChangeTo} from "../../store/selectors/app.selectors";
import {clearLocationToChangeToAction} from "../../store/actions";

const mapStateToProps = (state) => {
	return {
		locationToChangeTo: getLocationToChangeTo(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		clearLocationToChangeTo: () => {dispatch(clearLocationToChangeToAction())}
	};
};

export const RouterHelper = withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(RouterHelperComponent));
