import {values as lodashValues, keys as lodashKeys, startsWith, lowerFirst} from "lodash";
import {Locations} from "../constants/locations";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {getConfiguration} from "./toast";
import {RoleTypes} from "../constants/roleTypes";

/**
 * Errors examples:
 *

error.response.data.message = [{
	constraints: {
		isNotEmpty: "vehicleRegNumber should not be empty"
	}
	property: "vehicleRegNumber"
}]

error.response.data.message = [{
	constraints: {
		isDateString: "realTransportDate must be a ISOString"
		isNotEmpty: "realTransportDate should not be empty"
	}
	property: "realTransportDate"
}]

error.response.data.message = [{
	constraints: {
		isNotEmpty: "cardId should not be empty"
	}
	property: "cardId"
}]

error.response.data.message = "{"RealTransportDate":["Wybrana data jest nieprawidłowa. Wybierz datę w okresie +/- 1 dzień od"}
error.response.data.message = "options.request.kpoId with value "8ca4be3d-3a1e-4d29-88e1" must be of type string and a valid uuid."
error.response.data.message: "{"VehicleRegNumber":["'Vehicle Reg Number' must not be empty."],"RealTransportDate":["Wybrana data jest nieprawidłowa. Wybierz datę w okresie +/- 1 dzień od zaplanowanej daty rozpoczęcia odbierania."]}"

 *
 */

interface INormalizedError {
	[key: string]: string;
}

export function getNormalizedErrorObject(errorObject): INormalizedError[] {
	const messages = errorObject.response.data.message;

	if (Array.isArray(messages)) {
		return messages.map((message) => {
			return {
				name: message.property,
				value: lodashValues(message.constraints)[0],
			};
		});
	} else if (typeof messages === "string") {
		if (startsWith(messages, "{")) {
			const parsedMessages = JSON.parse(messages);
			const output: INormalizedError[] = [];

			// 	   {
			// 	   RealTransportDate: ["Wybrana data jest nieprawidłowa. Wybierz datę w ok…zień od zaplanowanej daty rozpoczęcia odbierania."],
			//     VehicleRegNumber: ["'Vehicle Reg Number' must not be empty."]
			//     }

			// {RealTransportDate: ["Wybrana data jest nieprawidłowa. Wybierz datę w ok…zień od zaplanowanej daty rozpoczęcia odbierania."]}

			for (const key in parsedMessages) {
				output.push({
					name: lowerFirst(key),
					value: parsedMessages[key][0],
				});
			}

			return output;
		} else {
			return [{
				name: "general",
				value: messages,
			}];
		}
	} else {
		return [];
	}
}

export function getErrorHandling(action, state) {
	if (action.error.message === "Network Error") {
		toast.error("Brak połączenia", getConfiguration());

		return {
			isDisconnected: true,
		};
	}

	const errors = getNormalizedErrorObject(action.error);

	switch (action.error.response.status) {
		case 403: //Forbidden
			return {
				// do nothing
			};
			// let isMissingBDOTokens = false;
			//
			// if (state.accessToken && state.accessToken.decoded && !state.accessToken.decoded.userinfo) {
			// 	isMissingBDOTokens = true;
			// }
			//
			// if (isMissingBDOTokens) {
			// 	return {
			// 		locationToChangeTo: Locations.UPDATE_TOKENS,
			// 	}
			// } else {
			// 	Cookies.remove("mbdo_at");
			// 	Cookies.remove("mbdo_ad");
			//
			// 	return {
			// 		isLoggedIn: false,
			// 		accessToken: null,
			// 		accountData: null,
			// 		locationToChangeTo: Locations.LOGIN,
			// 	};
			// }

		case 401:
			if (action.error.response.data.message === "AuthBdo") {// Nie mozna zalogowac sie do BDO - zle tokeny
				return {
					isLoggedIn: false,
					accessToken: null,
					accountData: null,
					error: [{
						name: "login",
						value: "Niepoprawna nazwa użytkownika lub hasło. Skontaktuj się z administratorem. Kod błędu #10",
					}]
				};
			} else if (action.error.response.data.message === "AuthCredentials") {
				return {
					isLoggedIn: false,
					accessToken: null,
					error: [{
						name: "login",
						value: "Niepoprawna nazwa użytkownika lub hasło."
					}]
				};
			} else if (action.error.response.data.message === "AuthBdoEupItems") { // Przypadek gdy jest wiecej miejsc prowadzenia dzialalnosci, trzeba pokazac ekran wyboru
				return {
					placesOfBusiness: action.error.response.data.payload.eupItems,
					isLoggedIn: false,
					accessToken: null,
					locationToChangeTo: Locations.SELECT_PLACE_OF_BUSINESS,
				};
			} else if (action.error.response.data.error === "Unauthorized") {
				Cookies.remove("mbdo_at");
				Cookies.remove("mbdo_ad");

				return {
					isLoggedIn: false,
					accessToken: null,
					accountData: null,
					locationToChangeTo: Locations.LOGIN,
				};
			}
		case 400:
			if (action.type === "TYPE_UPDATE_BDO_CREDENTIALS_FAILURE") {
				return {
					error: [{
						name: "clientId",
						value: "Niepoprawny 'Client ID' lub 'Client Secret'",
					}, {
						name: "clientKey",
						value: "Niepoprawny 'Client ID' lub 'Client Secret'",
					}],
					locationToChangeTo: null,
				};
			} else if (action.type === "TYPE_LOGIN_FAILURE") {
				return {
					error: [{
						name: "general",
						value: "Logujesz się do złego typu aplikacji. Wyróżniamy aplikacje dla Przewoźnika oraz Odbiorcy.",
					}],
					locationToChangeTo: null,
				};
			} else if (action.type === "TYPE_CONFIRM_RECEPTION_FAILURE") {
				if (containsError("correctedWasteMass", errors)) {
					//Nadpisuje error message z BDO bo jest glupi
					return {
						error: [{
							name: "correctedWasteMass",
							value: "Waga może zawierać tylko cztery liczby po przecinku.",
						}],
						locationToChangeTo: null,
					};
				} else {
					const generalError = getError("general", errors);

					if (generalError) {
						return {
							error: [{
								name: "general",
								value: "W miedzyczasie karta zmieniła swój status.",
							}],
							locationToChangeTo: null,
						};
					}
				}
			} else if (action.type === "TYPE_REJECT_RECEPTION_FAILURE") {
				const generalError = getError("general", errors);

				if (generalError) {
					return {
						error: [{
							name: "general",
							value: "W miedzyczasie karta zmieniła swój status.",
						}],
						locationToChangeTo: null,
					};
				}
			}

			return {
				error: errors,
				locationToChangeTo: null,
			};
		case 429:
			return {
				error: [{
					name: "general",
					value: "Wykonujesz zbyt wiele akcji. Spróbuj ponownie za kilka minut.",
				}],
				locationToChangeTo: null,
			};
		default:
			return {
				error: [{
					name: "general",
					value: "Wystąpił błąd",
				}],
				locationToChangeTo: null,
			};
	}
}

export function getError(type, errors) {
	if (!type || !errors) {
		return null;
	}

	return errors.find((error) => {
		return error.name === type;
	});
}

export function containsError(type, errors) {
	return Boolean(getError(type, errors));
}
