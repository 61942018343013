import React from "react";
import {useStyles} from "./advice.styles";
import classnames from "classnames";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/Info';

export function AdviceComponent(props) {
	const {children, type} = props;
	const classes = useStyles({});

	return (
		<div className={classnames(classes.root, {[classes.typeHighlight]: type === "highlight"}, {[classes.typeError]: type === "error"}, {[classes.typeInfo]: type === "info"})}>
			<div className={classes.iconWrapper}>
				{type === "info" || type === "highlight" ? <InfoIcon /> : <ErrorOutlineIcon />}
			</div>
			<div className={classes.textWrapper}>{children}</div>
		</div>
	);
}
