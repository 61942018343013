import {Field} from "react-final-form";
import * as React from "react";
import {Checkbox as MUICheckbox} from '@material-ui/core/';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useStyles} from "./checkbox.styles";
import classnames from "classnames";

const CheckboxAdapter = ({input, meta, serverErrors, children, checked, alignLabel = "center", value, ...rest}) => {
	const classes = useStyles({});
	const [isChecked, setChecked] = React.useState(checked || input.value || false);

	const handleChange = (event) => {
		setChecked(event.target.checked);

		return input.onChange(event.target.checked);
	};

	const rootClass = alignLabel === "center" ? classes.rootCenter : (alignLabel === "top" ? classes.rootTop : classes.rootBottom);

	return (
		<FormControlLabel
			classes={{label: classes.label, root: rootClass}}
			control={
				<MUICheckbox
					size="medium"
					checked={isChecked}
					onChange={handleChange}
					color="primary"
					{...rest}
				/>
			}
			label={children}
		/>
	);
};

export function Checkbox(props) {
	return (
		<Field
			component={CheckboxAdapter}
			{...props}
		/>
	);
}
