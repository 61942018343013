import React from "react";
import {useStyles} from "./accordion.styles";
import classnames from "classnames";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";

export function AccordionComponent(props) {
	const {children, title} = props;
	const classes = useStyles({});

	return (
		<ExpansionPanel classes={{root: classes.root, expanded: classes.expanded}}>
			<ExpansionPanelSummary
				classes={{root: classes.summaryRoot}}
				expandIcon={<ExpandMoreIcon />}
			>
				{title}
			</ExpansionPanelSummary>
			<ExpansionPanelDetails classes={{root: classes.detailsRoot}}>
				{children}
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
}
