import {getErrorHandling} from "../../../../helpers/errors";

export function getDeleteUserSuccessReducer(state, action) {
	return {
		...state,
		isLoading: false,
		isDisconnected: false,
		refreshCurrentPageStatus: true,
		error: null,
	};
}
