import {UserStatus} from "../../constants/userStatus";
import {hasAccountData} from "../selectors/app.selectors";

export function getGetUserSuccessReducer(state, action) {
	// const hasBDOTokens = !(state.accessToken && state.accessToken.decoded && !state.accessToken.decoded.userinfo);

	return {
		...state,
		isLoading: action.isLoading,
		isDisconnected: false,
		isLoggedIn: true,
		accountData: action.response.data,
		isFetchingAccountData: false,
		error: null,
		isBlocked: action.response.data.status === UserStatus.BLOCKED,
		isExpired: Boolean(action.response.data.isExpired),
		hasBDOTokens: Boolean(action.response.data.hasBdoCredentials),
		locationToChangeTo: action.locationToChangeTo,
	};
}
