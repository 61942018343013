import { makeStyles } from '@material-ui/core/styles';

export const globalStyles = makeStyles((theme) => ({
	root: {

	},
	header: {
		marginBottom: "20px",
		fontSize: "0.95rem",
	},
	button: {
		width: "100%"
	},
	clearSelect: {
		margin: 0
	},
	alignButton: {
		textAlign: "right"
	},
	centerButtons: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	centerButton: {
		"&:not(:first-child)": {
			marginLeft: theme.spacing(2),
		}
	},
	inputWidth: {
		width: "100%"
	},
	ladingResources: {
		padding: "20px 15px",
	}
}));

